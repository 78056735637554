<template>
    <div class="main-layout-section">
      
        <AdminHeader @setPodData="setPodData($event)" />
        <AdminPollingAndSockets />
        <div class="main-content" v-if="!game_init">
            <!-- start of games container section -->
            <div class="col-md-11 mx-auto">
    
                <div class="games-section-container">
                    <!-- start of images section -->
                    <div class="games-section-images">
                    </div>
                    <!-- end of images section -->
                    <!-- start of filters/list section -->
                    <div class="games-section-list">
                        <b-input-group class="d-flex text-center align-items-center games-section-list-search">
                            <b-input-group-prepend class="search-icon">
                                <i class="animate__animated animate__fadeIn animate__fast fa-solid fa-search icon-button"></i>
                            </b-input-group-prepend>
                            <b-form-input
                            type="text"
                            placeholder="search for a title"
                            class="search-box h-53"
                            autocomplete="off"
                            v-model="searchQuery"
                            ></b-form-input>
                            <b-input-group-append class="xbox-icon">
                                <span class="animate__animated animate__fadeIn animate__fast game-count">{{ (searchQuery !== '') ? filteredGames.length : total_games }}</span>
                                <i class="animate__animated animate__fadeIn animate__fast fa-brands fa-xbox icon-button"></i>
                            </b-input-group-append>
                        </b-input-group>
    
                        <b-overlay :show="api_state" rounded="sm" variant="light" opacity="1" class="overlay-bg">
                            <div class="games-section-list-filters mt-3">
                                <div class="games-section-list-filter-item">
                                    <div class="games-section-list-filter-item-header">
                                        Sort By
                                    </div>
                                    <div class="games-section-list-filter-item-body">
                                        <span :class="[selected_sort == sort_options.popular ? 'sort-active' : '']" @click="handleSortReq(sort_options.popular)">Popular</span>
                                        <span :class="[selected_sort == sort_options.newest ? 'sort-active' : '']" @click="handleSortReq(sort_options.newest)">Newest</span>
                                        <span :class="[selected_sort == sort_options.abc ? 'sort-active' : '']" @click="handleSortReq(sort_options.abc)">ABC</span>
                                    </div>
                                </div>
                                <div class="games-section-list-filter-item">
                                    <div class="games-section-list-filter-item-header">
                                        Filters
                                    </div>
                                    <div class="games-section-list-filter-item-body filters-list">
                                        <div class="filters-list-item">
                                            <span @click="handleFilterDropdown('rating-filter')" :class="[selected_filters.game_esrb.length > 0 ? 'sort-active' : '']">Rating</span>
                                            <div class="filters-list-item-menu" id="rating-filter">
                                                <ul>
                                                    <template v-for="item in game_esrbs">
                                                        <li :key="item.id" v-if="item.id !== ''" @click="handleFilterGamesList('game_esrb', item.id)" :class="[selected_filters.game_esrb.indexOf(item.id) !== -1 ? 'active' : '']">
                                                            {{ item.value }}
                                                        </li>
                                                    </template>
                                                    <hr>
                                                    <li @click="handleFilterDropdown('done')">Done</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="filters-list-item">
                                            <span @click="handleFilterDropdown('genre-filter')" :class="[selected_filters.game_genre.length > 0 ? 'sort-active' : '']">Genre</span>
                                            <div class="filters-list-item-menu" id="genre-filter">
                                                <ul>
                                                    <template v-for="item in game_genres">
                                                        <li :key="item.id" v-if="item.id !== ''" @click="handleFilterGamesList('game_genre', item.id)" :class="[selected_filters.game_genre.indexOf(item.id) !== -1 ? 'active' : '']">
                                                            {{ item.value }}
                                                        </li>
                                                    </template>
                                                    <hr>
                                                    <li @click="handleFilterDropdown('done')">Done</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <span>Released</span> -->
                                        <div class="filters-list-item">
                                            <span @click="handleFilterDropdown('storage-filter')" :class="[selected_filters.game_type.length > 0 ? 'sort-active' : '']">Storage</span>
                                            <div class="filters-list-item-menu" id="storage-filter">
                                                <ul>
                                                    <template v-for="item in game_types">
                                                        <li :key="item.id" v-if="item.id !== ''" @click="handleFilterGamesList('game_type', item.id)" :class="[selected_filters.game_type.indexOf(item.id) !== -1 ? 'active' : '']">
                                                            {{ item.value }}
                                                        </li>
                                                    </template>
                                                    <hr>
                                                    <li @click="handleFilterDropdown('done')">Done</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="games-section-list-data mt-3">
                                <div class="games-section-list-data-title" :class="[api_state ? 'zero-opacity' : '']">
                                    <h3>{{ game_selected !== null ? game_selected.GameName : 'Select A Game' }}</h3>
                                    <div class="games-section-list-data-title-attribs">
                                        <div class="games-section-list-data-title-attribs-item">
                                            <span class="heading">Genre</span>
                                            <span class="body">{{ game_selected !== null ? game_selected.GameGenre : '-' }}</span>
                                        </div>
                                        <div class="games-section-list-data-title-attribs-item">
                                            <span class="heading">Released</span>
                                            <span class="body">{{ game_selected !== null ? game_selected.ReleasedDate : '-' }}</span>
                                        </div>
                                        <div class="games-section-list-data-title-attribs-item">
                                            <span class="heading">Storage</span>
                                            <span class="body">{{ game_selected !== null ? game_selected.GameType[0].toUpperCase() + game_selected.GameType.slice(1) : '-' }}</span>
                                        </div>
                                        <div class="games-section-list-data-title-attribs-item">
                                            <span class="heading">Platform</span>
                                            <span class="body">
                                                <template v-if="game_selected !== null">
                                                    <span v-for="(pt, index) in game_selected.PlatformsFE" :key="index">
                                                        {{ (pt !== "" && Object.prototype.hasOwnProperty.call(xds_icons, pt)) ? xds_icons[pt].text.split(" ")[1] : "" }}
                                                    </span>
                                                </template>
                                                <span v-else>-</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="games-section-list-data-list" id="games-list" :class="[api_state ? 'zero-opacity' : '']">
                                    <div class="games-section-list-data-list-inner" id="games-listtt">
                                        <div class="games-section-list-data-list-item animate__animated animate__fadeIn animate__fast" tabindex="0" v-for="game in filteredGames" :key="game.GameId" @click="selectGame(game)" v-show="loaded_games.indexOf(game.ImageURL) !== -1">
                                            <img 
                                            :src="`${game.ImageURL}`" 
                                            alt="" 
                                            :class="[ game_selected !== null && game_selected.GameId == game.GameId ? 'selected-game' : '' ]" @load="loadGameImage(game.ImageURL)">
                                        </div>
                                        <p class="text-left no-results" v-if="filteredGames.length <= 0" :key="filteredGames.length">No games found at the moment!</p>
                                    </div>
                                </div>
                                <div class="games-section-list-data-play">
                                    <div class="games-section-list-data-play-buttons">
                                        <a href="javascript:;" class="btn" @click="handleBack">
                                            Back
                                        </a>
                                        <button 
                                        class="btn"
                                        @click="gamePlayExecute(game_selected.GameKey, game_selected.GameSearch, xd_type, game_selected.GameType)"
                                        v-if="game_selected !== null"
                                        >
                                        {{ xds_icons[xd_type].text }}
                                        </button>
                                        <button 
                                        class="btn disabled-btn"
                                        @click="disabledPlay" 
                                        v-else
                                        >
                                        {{ (xds_data[xd_type+'_disabled']) ? '' : xds_icons[xd_type].text }}
                                        </button>
                                    </div>
                                    <div class="games-section-list-data-play-chat">
                                        <div class="w-chat-box" @click="$root.$emit('live-chat-widget-visibility', 'show-hide');">
                                            <!-- <span><i class="fa-brands fa-rocketchat"></i></span> -->
                                            <span><img src="../../assets/images/chat-icon.png" alt="chat with us"></span>
                                        </div>
                                    </div>
    
                                </div>
                                
                                <!-- show notification -->
                                <div class="w-login-show notification-container w-100 mx-auto nogame-notification">
                                    <div
                                        class="
                                        animate__animated animate__fadeIn animate__fast 
                                        w-100
                                        "
                                        v-if="nogame_error !== '' && nogame_message !== ''"
                                    >
                                        <div class="notification-block warning w-100">
                                            <div class="notification-message w-100">
                                                <h5>{{ nogame_message }}</h5>
                                            </div>
                                            <div class="icon-warning"><i class="fa-solid fa-exclamation-circle"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                    <!-- end of filters/list section -->
                    <!-- start of images section -->
                    <div class="games-section-info">
                    </div>
                    <!-- end of images section -->
                </div>
            </div>
            <!-- end of games container section -->
        </div>
    
        <div class="" v-if="game_init">
            <div class="w-logo mb-5 pt-5">
                <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
            </div>
    
            <div class="main-content">
                <h2 class="page-heading animate__animated animate__fadeIn animate__fast">Getting your game ready!</h2>
                <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__fast">Hold tight...</h3>
                <!-- start of buttons section -->
                <div class="dashboard-buttons-container col-md-3">
                    <div class="logout-loading-image mx-auto animate__animated animate__fadeIn animate__fast">
                        <object type="image/svg+xml" :data="require(`../../assets/images/bits.svg`)" style="width:120px;"></object>
                    </div>
                    <div class="logout-reason mt-70 animate__animated animate__fadeIn animate__fast">
                        <!-- <p class="text-center" style="font-weight: 600;">Whenever you are ready to<br>logout or return to the menu,<br>use the physical button panel.</p> -->
                    </div>
    
                    <div class="w-login-show notification-container w-alert-340 mx-auto">
                        <div
                            class="
                            animate__animated animate__fadeIn animate__fast 
                            w-alert-340
                            "
                            v-if="!game_started"
                        >
                            <div class="notification-block warning">
                                <div class="notification-message w-alert-290">
                                    <h5>{{ (game_start_error.is_exist) ? this.game_start_error.text_head : "Still Here? Something went wrong." }}</h5>
                                    <p>{{ (game_start_error.is_exist) ? this.game_start_error.text_sub : "Chat with us below and we’ll sort this out for you" }}</p>
                                </div>
                                <div class="icon-warning"><i class="fa-solid fa-exclamation-circle"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end of buttons section -->
            </div>
        </div>

        <!-- start of footer section -->
        <div class="col-sm-12 mx-auto player-games-footer">
            <AdminFooter type="inner" class="animate__animated animate__fadeIn animate__fast" />
        </div>
        <!-- end of footer section -->
  
    </div>
  </template>
  
  <script>
  import AdminHeader from "@/views/admin/AdminHeader.vue";
  import AdminPollingAndSockets from "@/views/admin/AdminPollingAndSockets.vue";
  import { getGamesList } from "../../services/PlayerService";
  import { global_config } from "@/config/config.js";
  import AdminFooter from "@/views/admin/AdminFooter.vue";
  import { handleDocumentHeight } from "../../services/Guard";
  import { getXdTypes } from "../../services/FlaskService";
  import { adminRunCommand } from "../../services/AdminService";
  
  export default {
      name: "AdminPodGames-view",
      components: { AdminHeader, AdminFooter, AdminPollingAndSockets },
      data() {
          return {
              xd_type: this.$route.params.xd,
              games: [],
              original_games: [],
              searchQuery:'',
              api_state: false,
              page: 1,
              last_page: 1,
              game_selected: null,
              window_width: window.innerWidth,
              xds_data: {
                  "xd1" : "", 
                  "xd2" : "", 
                  "xb_disabled": true, 
                  "ps_disabled": true, 
                  "sw_disabled": true, 
                  "pc_disabled": true, 
                  "pod_disabled": true
              },
              xds_icons: global_config.XD_ICONS,
              game_init: false,
              game_started: true,
              lazy_load_props: {
                  blank: true,
                  blankColor: '#d3d3d3'
              },
              sort_options: {
                  'newest' : 'newest',
                  'popular' : 'popular',
                  'abc' : 'abc',
              },
              selected_sort: 'popular',
              selected_filters: {
                  'game_type': [],
                  'game_genre': [],
                  'game_esrb': []
              },
              game_genres: global_config.GAME_GENERES,
              game_types: global_config.GAME_TYPES,
              game_esrbs: global_config.GAME_ESRBS,
              loaded_games: [],
              filters_state:'',
              total_games:0,
              nogame_error: '',
              nogame_message: '',
              selected_pod_ids: [],
              pod_id: ''
          };
      },
  
    computed: {
        filteredGames () {
            if(this.searchQuery) {
                return this.games.filter(item => {
                return item.GameName.toLowerCase().includes(this.searchQuery.toLowerCase())
                });
            }
            else {
                return this.games;
            }
        },
        game_start_error() {
            return this.$store.getters.getMessageDetail('player-games-start-error');
        },
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        getPodXdTypes() {
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            this.pod_id = pod_data.pod_id_key;
            // get xd types for buttons
            getXdTypes().then(xd => {
                if(xd.data !== undefined) {
                    this.xds_data = xd.data;
                    this.gamesList();
                    this.selected_pod_ids = JSON.parse(localStorage.getItem('admin_selected_pods')).items;
                }
            });
        },
        gamesList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id,
                type: 'player',
                page: this.page,
                screen: this.window_width < 768 ? 'sm' : 'lg',
                xd: this.xd_type,
                sort: this.selected_sort,
                filters: this.selected_filters,
            };
            this.api_state = true;
            getGamesList(params).then(response => {
                this.api_state = false;
                this.games = response.data.games;
                this.page = response.data.current_page + 1;
                this.last_page = response.data.last_page;
                this.total_games = response.data.total_rows;
                this.original_games = JSON.parse(JSON.stringify(this.games));
            })
        },
        selectGame(game) {
            if(this.game_selected == null) {
                this.game_selected = game;
            }
            else {
                if(this.game_selected.GameId == game.GameId) {
                    // this.game_selected = null;
                }
                else {
                    this.game_selected = game;
                }
            }
        },
        playGame(gid, search, platform, install=null) {
            document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
            document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
            setTimeout(() => {
                document.getElementById('main-header-admin').scrollIntoView()
                this.$children.forEach(function(item) {
                    if(item.$options._componentTag == "AdminPollingAndSockets") {
                        item.playGame(gid, search, platform, install);
                    }
                });
            }, 1500);
        },
        handleBack() {
            document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
            document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
            setTimeout(() => {
                this.$router.push({ path: '/admin/dashboard' });
            }, 500);
        },
        handleSortReq(type) {
            if(this.selected_sort !== type) {
                this.selected_sort = type;
                this.page = 1;
                this.last_page = 1;
                this.gamesList();
            }
        },
        handleFilterDropdown(type='') {
            // hide all dropdowns
            var elems = document.getElementsByClassName('filters-list-item-menu');
            for (var i = 0; i < elems.length; i ++) {
                elems[i].style.display = 'none';
            }
            // now open clicked one
            if(type !== '' && type !== 'done') {
                document.getElementById(type).style.display = 'block';
                this.filters_state = JSON.stringify(this.selected_filters);
            }
            if(type == 'done' && JSON.stringify(this.selected_filters) !== this.filters_state) {
                this.page = 1;
                this.last_page = 1;
                this.gamesList();
            }
        },
        handleFilterGamesList(type, value) {
            if(this.selected_filters[type].indexOf(value) !== -1) {
                this.selected_filters[type].splice(this.selected_filters[type].indexOf(value), 1);
            }
            else {
                this.selected_filters[type].push(value);
            }
        },
        loadGameImage(game) {
            this.loaded_games.push(game);
        },
        disabledPlay() {
            this.nogame_error = 'warning';
            this.nogame_message = 'Please select a game to continue.';
            // hide error div
            setTimeout(() => {
                this.nogame_error = '';
                this.nogame_message = '';
            }, 5000);
        },
        gamePlayExecute(gid, search, platform, install=null, is_api=true) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                token: user_data.token,
                command_type: 'admin_play_game',
                command_value: gid,
                command_platform: platform,
                pod_ids: this.selected_pod_ids,
                command_search: search,
                command_install: install,
                request_pod_id: pod_data.pod_id_key
            };

            if(is_api) {
                adminRunCommand(params).then(response => {
                    // only play game on current pod if pod in selected ones
                    if(this.selected_pod_ids.indexOf(this.pod_id) !== -1) {
                        this.playGame(gid, search, platform, install);
                    }
                    if(response.status) {
                        this.$root.$emit("toast_message", {'type' : 'success', 'message' : response.message});
                    }
                    else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                    }
                });
            }
            else {
                // only play game on current pod if pod in selected ones
                if(this.selected_pod_ids.indexOf(this.pod_id) !== -1) {
                    this.playGame(gid, search, platform, install);
                }
            }
        },
    },

    mounted() {
        this.getPodXdTypes();
        handleDocumentHeight();
        document.getElementById('games-list').onscroll = () => {
            let bottomOfWindow = document.getElementById('games-list').scrollTop + document.getElementById('games-list').offsetHeight === document.getElementById('games-list').scrollHeight;
            if (bottomOfWindow && !this.api_state && this.page <= this.last_page) {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    session_id_key : pod_data.session_id_key,
                    token: user_data.token,
                    user_id: user_data.user_id,
                    type: 'player',
                    page: this.page,
                    screen: this.window_width < 768 ? 'sm' : 'lg',
                    xd: this.xd_type,
                    sort: this.selected_sort,
                    filters: this.selected_filters,
                };
                this.api_state = true;
                getGamesList(params).then(response => {
                    this.api_state = false;
                    this.page = response.data.current_page + 1;
                    this.last_page = response.data.last_page;
                    this.total_games = response.data.total_rows;
                    this.games = this.games.concat(response.data.games);
                })
            }
        };
        // capture game messages
        if(
            Object.prototype.hasOwnProperty.call(this.$root, "_events") && 
            Object.prototype.hasOwnProperty.call(this.$root._events, "game_message")
        ) {
            this.$root.$off("game_message");
        }
        this.$root.$on('game_message', (message) => {
            if(message.type == 'init') {
                this.game_init = true;
                this.game_started = true;
            }
            else if(message.type == 'success') {
                this.$router.push('/admin/dashboard');
            }
            else if(message.type == 'fail') {
                this.game_started = false;
            }
        });

        // capture admin websocket messages
        if(
            Object.prototype.hasOwnProperty.call(this.$root, "_events") && 
            Object.prototype.hasOwnProperty.call(this.$root._events, "admin_play_game")
        ) {
            this.$root.$off("admin_play_game");
        }
        this.$root.$on('admin_play_game', (message) => {
            if(Object.prototype.hasOwnProperty.call(message, "admin_websocket") && message.admin_websocket) {
                if(message.admin_websocket.command_type == 'admin_play_game') {
                    localStorage.setItem('admin_selected_pods', JSON.stringify({'items' : [message.admin_websocket.command_pod]}));
                    this.selected_pod_ids = [message.admin_websocket.command_pod];
                    this.playGame(message.admin_websocket.command_value, message.admin_websocket.game_search, message.admin_websocket.game_platform, message.admin_websocket.game_install);
                }
            }
        });

        let params = this.$route.params;
        if(Object.prototype.hasOwnProperty.call(params, "admin_websocket") && params.admin_websocket) {
            if(params.admin_websocket.command_type == 'admin_play_game') {
                localStorage.setItem('admin_selected_pods', JSON.stringify({'items' : [params.admin_websocket.command_pod]}));
                this.selected_pod_ids = [params.admin_websocket.command_pod];
                this.gamePlayExecute(params.admin_websocket.command_value, params.admin_websocket.game_search, params.admin_websocket.game_platform, params.admin_websocket.game_install, false);
            }
        }
    },
    created() {
        if(typeof this.$route.params.xd == 'undefined' || this.$route.params.xd == '') {
            this.$router.push({ path: '/admin/dashboard' })
        }
    }
  };
  </script>
  <style scoped>
  .main-layout-section .main-content .games-section-container {
      min-height: 87vh;
  }
  .w-footer-block-inner {
      padding-top: 0px;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .disabled-btn {
      opacity: 0.7;
  }
  .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
      height: 52vh;
  }
  .nogame-notification {
      margin-top: 10px;
  }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
      .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
          height: 48vh;
      }
  }
  @media screen and (max-width: 768px) {
      .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
          height: 46vh;
      }
      .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-play .games-section-list-data-play-chat {
          display: flex;
          width: 25%;
          justify-content: end;
      }
      .main-layout-section .player-games-footer {
          display: none;
      }
  }
  @media screen and (max-width: 768px) and (min-width: 426px) {
      .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
          height: 44vh;
      }
      .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-play {
          margin-bottom: 0px;
      }
  }
  @media screen and (max-width: 425px) {
      .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
          height: 46vh;
      }
      .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-play {
          margin-bottom: 0px;
      }
  }
  </style>